@import url(
  https://fonts.googleapis.com/css?family=Poppins:100,
  100italic,
  200,
  200italic,
  300,
  300italic,
  regular,
  italic,
  500,
  500italic,
  600,
  600italic,
  700,
  700italic,
  800,
  800italic,
  900,
  900italic
);

@import url("https://fonts.cdnfonts.com/css/clash-display");

/* @font-face {
  font-family: "NeueHaasDisplay";
  src: url("./fonts/NeueHaasDisplayLight.ttf");
} */
* {
  padding: 0;
  margin: 0;
  text-decoration: none;
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
  letter-spacing: 1px;
  --inactive: #afa9bb;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

input, input:before, input:after {
  -webkit-user-select: initial;
  -khtml-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;
  user-select: initial;
 } 
 
a {
  text-decoration: none;
  color: #2A88BF;
}


button{
  height: 48px;
  color: #101E21 !important;
  font-weight: 500 !important;
}
nav {
  z-index: 1;
}
.card, .card-header, .list-group, .list-group-item{
  background: transparent;
  width: 100%;
}

.list-group-item div{
  display: flex;
}

.list-group-item span.card-label{
  min-width: 100px;
  font-weight: 600;
}
a.bg-custom:hover{
  color: black !important;
}
.modal-dialog {
  width: 430px;
  max-width: calc(100% - 32px);
  margin: 100px auto;
}
.modal-header .modal-title {
  font-weight: 600;
  font-size: 21px;
  line-height: 150%;
}
.modal-header {
  border: none;
  padding: 30px 36px 15px 36px;
}
.modal-body {
  padding: 0px 36px 30px 36px;
}
.btn-close {
  background: none;
}

.alert .btn-close:after{
  color: #000;
}
#responsive-navbar-nav {
  flex-grow: initial;
}
#responsive-navbar-nav > .navbar-nav {
  gap: 50px;
}

#collasible-nav-dropdown {
  height: 52px;
  display: flex;
  align-items: center;
  position: relative;
}

.dropdown-menu.show{
  left: unset;
  right: -10px;
}
.ps-sidebar-root {
  background: linear-gradient(180deg, rgba(17, 32, 35, 0.4) 0%, rgba(10, 19, 21, 0.4) 100%);
  border: none !important;
}

.sidebar-logo {
  padding-top: 25px;
  padding-bottom: 25px;
}
.sidebar-logo .ps-menu-button{
  height: 60px;
}
.sidebar-logo .ps-menu-icon{
  width: 170px;
}
.ps-menuitem-root.sidebar-logo .ps-menu-button:hover {
  background: transparent !important;
}
.chat-history {
  height: calc(100% - 125px) !important;
}

.setting-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.alert {
  z-index: 12;
}

.nav-item .dropdown-menu .dropdown-item:hover{
  background: transparent;
}
@media (min-width: 1500px) {
}

@media (max-width: 991px) {
}

@media (max-width: 767px) {
}

@media (max-width: 575px) {
  #responsive-navbar-nav {
    padding: 20px;
  }
  .list-group-item div{
    flex-flow: row wrap;
  }
  #responsive-navbar-nav > .navbar-nav {
    gap: 12px;
  }
  .chat-history {
    height: calc(100% - 125px) !important;
  }
}

@media (max-width: 374px) {
  nav .login-buttons {
    width: 100%;
  }
}
